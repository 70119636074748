import { defineComponent, inject, onMounted } from 'vue';
import SearchSection from '@/components/home/SearchSection.vue';
import Header from '@/components/Header.vue';
import Banner from '@/components/home/Banner.vue';
import FeaturedSection from '@/components/home/FeaturedSection.vue';
import PopularCities from '@/components/home/PopularCities.vue';
import HitoSection from '@/components/home/HitoSection.vue';
import Footer from '@/components/Footer.vue';
import { useStore } from 'vuex';
import { setKeywordMeta } from '@/utils/setKeywordMeta';
export default defineComponent({
  name: 'TravelHome',
  components: {
    SearchSection: SearchSection,
    HeaderComponent: Header,
    Banner: Banner,
    HitoSection: HitoSection,
    FeaturedSection: FeaturedSection,
    PopularCities: PopularCities,
    FooterComponent: Footer
  },
  setup: function setup() {
    var store = useStore();
    var t = inject('t');
    onMounted(function () {
      store.dispatch('form/setSearchCondition', {
        field: 'business_type',
        value: 1
      });
      store.dispatch('options/setGuestNumber', 1);
      setKeywordMeta('1'); // set quarantine hotel's business type meta keyword
    });
    return {
      t: t
    };
  }
});