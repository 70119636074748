import { defineComponent, inject } from 'vue';
import { useHotelOption } from '@/hook/useHotelOption';
import { useSearchHotels } from '@/hook/useSearchHotels';
import { useStore } from 'vuex';
import Slider from '@/components/share/Sliders.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: {
    Slider: Slider,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var store = useStore();
    var isMobile = inject('isMobile');
    var t = inject('t');

    var _useHotelOption = useHotelOption(),
        hotelOptionData = _useHotelOption.hotelOptionData;

    var _useSearchHotels = useSearchHotels(),
        handleGoHotels = _useSearchHotels.handleGoHotels;

    var handleSearchFacilities = function handleSearchFacilities(tag) {
      store.dispatch('form/setSearchCondition', {
        field: 'filter',
        value: [tag]
      });
      handleGoHotels();
    };

    return {
      t: t,
      hotelOptionData: hotelOptionData,
      handleSearchFacilities: handleSearchFacilities,
      isMobile: isMobile
    };
  }
});