import { computed, defineComponent, inject } from 'vue';
import { useStore } from 'vuex';
import Slider from '@/components/share/Sliders.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { BASE_URL } from '@/api';
import { useSearchHotels } from '@/hook/useSearchHotels';
export default defineComponent({
  components: {
    Slider: Slider,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var store = useStore();
    var isMobile = inject('isMobile');
    var t = inject('t');

    var _useSearchHotels = useSearchHotels(),
        handleSearch = _useSearchHotels.handleSearch;

    var twPopularCities = computed(function () {
      return store.state.options.twPopularCities;
    });
    var jpPopularCities = computed(function () {
      return store.state.options.jpPopularCities;
    });

    var getCityImg = function getCityImg(path) {
      return require("@/assets/images/".concat(path, "1x.png"));
    };

    var clickCity = function clickCity(cityId) {
      store.dispatch('form/setSearchCondition', {
        field: 'city',
        value: cityId
      });
      handleSearch();
    };

    return {
      t: t,
      isMobile: isMobile,
      BASE_URL: BASE_URL,
      twPopularCities: twPopularCities,
      jpPopularCities: jpPopularCities,
      getCityImg: getCityImg,
      clickCity: clickCity
    };
  }
});