import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5da20c1b"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "hito"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "list"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "img-wrapper"
};
var _hoisted_6 = ["src", "alt"];
var _hoisted_7 = {
  class: "tag"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = ["onClick"];
var _hoisted_10 = {
  class: "img-wrapper"
};
var _hoisted_11 = ["src", "alt"];
var _hoisted_12 = {
  class: "tag"
};
var _hoisted_13 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$hotelOptionData, _ctx$hotelOptionData2, _ctx$hotelOptionData3;

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_slider = _resolveComponent("slider");

  return (_ctx$hotelOptionData = _ctx.hotelOptionData) !== null && _ctx$hotelOptionData !== void 0 && _ctx$hotelOptionData.exclusive_hotel ? (_openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t('homepage.unique_hotels_inns')), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$hotelOptionData2 = _ctx.hotelOptionData) === null || _ctx$hotelOptionData2 === void 0 ? void 0 : _ctx$hotelOptionData2.exclusive_hotel, function (tags, index) {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "card",
      key: index,
      onClick: function onClick($event) {
        return _ctx.handleSearchFacilities(tags.lang_key);
      }
    }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
      class: "img",
      src: tags.image,
      alt: _ctx.t(tags.lang_key)
    }, null, 8, _hoisted_6)]), _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.t(tags.lang_key)), 1)], 8, _hoisted_4)), [[_vShow, _ctx.isMobile]]);
  }), 128))]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_slider, {
    sliders: (_ctx$hotelOptionData3 = _ctx.hotelOptionData) === null || _ctx$hotelOptionData3 === void 0 ? void 0 : _ctx$hotelOptionData3.exclusive_hotel,
    unit: 4
  }, {
    previous: _withCtx(function (props) {
      return [_createElementVNode("button", {
        class: "slider-btn left",
        type: "button",
        onClick: function onClick($event) {
          return props.previous();
        }
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "icon_48_leftarrow_big_enable"
      })], 8, _hoisted_8)];
    }),
    container: _withCtx(function () {
      var _ctx$hotelOptionData4;

      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$hotelOptionData4 = _ctx.hotelOptionData) === null || _ctx$hotelOptionData4 === void 0 ? void 0 : _ctx$hotelOptionData4.exclusive_hotel, function (tags, index) {
        return _openBlock(), _createElementBlock("div", {
          class: "card",
          key: index,
          onClick: function onClick($event) {
            return _ctx.handleSearchFacilities(tags.lang_key);
          }
        }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
          class: "img",
          src: tags.image,
          alt: _ctx.t(tags.lang_key)
        }, null, 8, _hoisted_11)]), _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.t(tags.lang_key)), 1)], 8, _hoisted_9);
      }), 128))];
    }),
    next: _withCtx(function (props) {
      return [_createElementVNode("button", {
        class: "slider-btn right",
        type: "button",
        onClick: function onClick($event) {
          return props.next();
        }
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "icon_48_rightarrow_big_enable"
      })], 8, _hoisted_13)];
    }),
    _: 1
  }, 8, ["sliders"])], 512), [[_vShow, !_ctx.isMobile]])])) : _createCommentVNode("", true);
}